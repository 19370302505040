.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    /* overflow: scroll; */
  
    gap: 40px;
 
    box-shadow: "2px 2px 2px 2px rgba(0, 0, 0, 0.05)";
    /* max-width: 1920px;
    margin: 20px auto;
    padding: 0 20px;
    width: 100%; */
    
  }
  .my-masonry-grid_column {
    width: 100%!important;
    min-width: 400px;
    max-width: 400px;
    /* background-clip: padding-box; */
  }
  
  /* Style your items */
  .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
 
    margin-bottom: 40px;
  }

 
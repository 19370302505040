.rdrDateDisplay{
  margin: 0.833em;
}

.rdrDateDisplayWrapper{
  background-color: rgb(255, 255, 255);
  border-bottom:  1px solid #004B92;
}

.rdrDateDisplayItemActive{
  border-color: #004B92;
}

.rdrDateDisplayItem{
  border-radius: 10px;
}

.rdrDateDisplayItem input {
  color: #004B92;
}

.rdrWeekDay {
  font-weight: 500;
  line-height: 2.667em;
  color: #004B92;
}

.rdrDayToday .rdrDayNumber span:after{
  background: #004B92;
}

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
  background: #004B92;
}

.rdrDay {
  background: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 0;
  padding: 0;
  line-height: 3.000em;
  height: 3.000em;
  text-align: center;
  color: #1d2429;
}

.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
  background: rgba(255, 255, 255, 0.09);
  position: absolute;
  top: 3px;
  left: 0px;
  right: 0px;
  bottom: 3px;
  pointer-events: none;
  z-index: 1;
  border-color: #004B92;
}

.rdrNextPrevButton{
  background-color: #0000;
}

.rdrPprevButton i {
  border-width: 10px 10px 10px 10px;
  border-color: transparent #0000 transparent transparent;
  background: url("data:image/svg+xml;utf8,<svg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M21.875 26.25L13.125 17.5L21.875 8.75' stroke='MediumBlue' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>") no-repeat;
  background-position: center;
}

.rdrNextButton i {
  border-width: 10px 10px 10px 10px;
  margin: 0 0 0 7px;
  border-color: transparent #0000 transparent transparent;
  background: url('data:image/svg+xml;utf8,<svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.125 26.25L21.875 17.5L13.125 8.75" stroke="MediumBlue" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') no-repeat;
  background-position: center;
}

.rdrNextPrevButton:hover{
  background-color: #0000;
}


/* orange theme */
.rdrCalendarWrapper.rdrDateRangeWrapper.orange {
  .rdrDateDisplayWrapper {
    background-color: rgb(255, 255, 255);
    border-bottom:  1px solid #FCAF20;
    .rdrDateDisplayItemActive{
    border-color: #FCAF20;

  }
}

  .rdrDateDisplayItem{
    border-radius: 10px;
  }

  .rdrDateDisplayItem input {
    color: #FCAF20;
  }

  .rdrWeekDay {
    font-weight: 500;
    line-height: 2.667em;
    color: #FCAF20;
  }

  .rdrDayToday .rdrDayNumber span:after{
    background: #FCAF20;
  }

  .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
    background: #FCAF20;
  }

  .rdrDay {
    background: transparent;
    -webkit-user-select: none;
      -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border: 0;
    padding: 0;
    line-height: 3.000em;
    height: 3.000em;
    text-align: center;
    color: #1d2429;
  }

  .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
    background: rgba(255, 255, 255, 0.09);
    position: absolute;
    top: 3px;
    left: 0px;
    right: 0px;
    bottom: 3px;
    pointer-events: none;
    z-index: 1;
    border-color: #FCAF20;
  }

  .rdrNextPrevButton{
    background-color: #0000;
  }

  .rdrPprevButton i {
    border-width: 10px 10px 10px 10px;
    border-color: transparent #0000 transparent transparent;
    background: url("data:image/svg+xml;utf8,<svg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M21.875 26.25L13.125 17.5L21.875 8.75' stroke='orange' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>") no-repeat;
    background-position: center;
  }

  .rdrNextButton i {
    border-width: 10px 10px 10px 10px;
    margin: 0 0 0 7px;
    border-color: transparent #0000 transparent transparent;
    background: url('data:image/svg+xml;utf8,<svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.125 26.25L21.875 17.5L13.125 8.75" stroke="orange" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') no-repeat;
    background-position: center;
  }

  .rdrNextPrevButton:hover{
     background-color: #0000;
    }
  
}

.swiper-button-next {
    right:0;
    background-image: url('../../icons//general/customNavRightArr.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    /* background-color: #f7fafa; */
    background-color: transparent;
    /* height: 100%;
    top:calc(50% - 13px) */
}

.swiper-button-prev {
    left:0;
    background-image: url('../../icons//general/customNavLeftArr.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center;
    /* background-color: #f7fafa; */
    background-color: transparent;
    /* height: 100%;
    top:calc(50% - 13px) */
}

/* .swiper-button-prev.pmArrow, .swiper-button-next.pmArrow {
    background-color: red;
} */


.swiper-button-next::after, .swiper-button-prev::after{
    display: none;
  }


.swiper-button-prev.swiper-button-disabled , .swiper-button-next.swiper-button-disabled {
    display:none
}



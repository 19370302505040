.calc-num-input {
    width: 100%;
    padding: 0 10px;
    text-align: right;
    font-size: 28px;
    font-weight: bold;
}

.calc-popup-input {
    width: 100%;
    height:40px;
    padding: 0 10px;
    text-align: left;
}
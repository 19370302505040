@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;600;700&display=swap');

html,
body,
#root {
    height: 100%;
    width: 100%;
    // max-width: 1920px;
    background-color: #e5e5e5;
    margin: auto;
    // overflow: hidden;
    // overflow-y: scroll;
    font-family: 'Noto Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    th {
        font-family: 'Noto Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        sans-serif;
    }
}

